import { useState, useEffect, Fragment } from "react";
import PropTypes from 'prop-types';
import { SessionService } from "../../services/DataService";
import TimeUtils from "../../utils/TimeUtils";
import { Translate, translate } from "react-i18nify";
import { FromToPickerDate } from "../../components/FromToPicker";
import Confirmdownload from "./ConfirmDownload";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
    Typography,
    IconButton,
    Button,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Collapse,
    Box,
    CircularProgress,
    Divider
  } from "@mui/material";
import { DayDataDialog } from "./DayDataDialog";
import { MultipleDayDialog } from "./MultipleDayDialog";

const DataTab = (props) => {
    // const downloadReport = props.downloadReport;
    const id = props.id;
    const [dataFrom, setDataFrom] = useState(new Date().setDate(new Date().getDate() - 6)); // last 7 days
    const [dataTo, setDataTo] = useState(new Date());
    const [reportDays,setReportDays] = useState([]);
    const [selected, setSelected] = useState([]);
    const [toDownload, setToDownload] = useState([]);
    const [openConfirmDownload, setOpenConfirmDownload] = useState(false);
    const [downloadType, setDownloadType] = useState("")
    const [IsExpanded, setIsExpanded] = useState(false);
    const [openDayDialog, setOpenDayDialog] = useState(false);
    const [dialogDate, setDialogDate] = useState(null);

    const [multipleDayFrom, setMultipleDayFrom] = useState(new Date().setDate(new Date().getDate() - 4)); // last 5 days
    const [multipleDayTo, setMultipleDayTo] = useState(new Date());
    const [multipleDaySpan, setMultipleDaySpan] = useState([]);
    const [multipleDayData, setMultipleDayData] = useState([]);
    const [multipleDayDataLoading, setMultipleDayDataLoading] = useState(false);
    const [openMultipleDayDialog, setOpenMultipleDayDialog] = useState(false);
    let dayColor = "#78c7f5";
    
    // multiple day report
    useEffect(() => { // update multidayTo if span is more than 5 days
      let nDays = TimeUtils.getDatesBetweenTwoDates(new Date(multipleDayFrom), multipleDayTo);
  
      if (nDays?.length > 5) {
        setMultipleDayTo(nDays[4]); // change to 5th day if inputted span is more than 5 days
      } else {
        setMultipleDaySpan(nDays);
      }
      
    }, [multipleDayFrom]);
  
    useEffect(() => { // update multidayFrom if span is more than 5 days
      let nDays = TimeUtils.getDatesBetweenTwoDates(new Date(multipleDayFrom), multipleDayTo);
  
      if (nDays?.length > 5) {
        setMultipleDayFrom(nDays[nDays?.length - 1 - 4]); // -1 for zero indexing, -4 for 5 days        
      } else {
        setMultipleDaySpan(nDays);
      }
      
    }, [multipleDayTo]);
  
    useEffect(() => {
      setMultipleDayDataLoading(true);
      getMultipleDayData()
    }, [multipleDaySpan]);


    const updateDates = () =>  {
      let nDays = TimeUtils.getDatesBetweenTwoDates(new Date(multipleDayFrom), multipleDayTo);
    }

    const getMultipleDayData = async () => { // get detailed data for multiday span
      let from = TimeUtils.localToUtc(TimeUtils.getBeginningOfDay(multipleDaySpan[0]));
      let to = TimeUtils.localToUtc(TimeUtils.getEndingOfDay(multipleDaySpan[multipleDaySpan?.length - 1]));
      let apiResultDetailedSessions = await SessionService.getDetailedSessionsByPatientId(
        id,
        from,
        to
      );
      setMultipleDayData(apiResultDetailedSessions.data);
    }

    const createMultipleDays = (data) => {
      // KAC TO DO: add that background color changes for the days with no data
      // add how many hours of data are available for each day
      console.log(data)
      return data.map(day => {
        // console.log(day)
          return ( <Box sx={{width:"180px", backgroundColor:dayColor, mx:2, borderRadius: 1, display:'flex', justifyContent: 'center', flexDirection: 'column', }}>
            <Box sx={{display:'flex', justifyContent: 'center'}}>
              <Typography key={day} variant="h7">{translate(day.toLocaleTimeString('en-us', {weekday:'long'}).split(' ')[0])}</Typography>
            </Box>
            <Box sx={{display:'flex', justifyContent: 'center'}}>
              <Typography key={day} variant="h7">{TimeUtils.formatDateYYMMDD(day)}</Typography>
            </Box>
            
          </Box>
          
          )
      })
    }

    const handleMultipleDayDialogOpen = () => {
      setOpenMultipleDayDialog(true)
    }

    const closeMultipleDayDialog = () => {
      setOpenMultipleDayDialog(false);
    }

    // end of multiple day report section





    useEffect(() => {
      const insideNewRange = selected.filter((row) => {
        return row.date >= dataFrom && row.date <= dataTo
      })
      setSelected(insideNewRange)
      getReportAndSessionData()
    }, [dataFrom, dataTo]);


    useEffect(() => {
        if(IsExpanded && reportDays.length <= 0)
          getReportAndSessionData()
    }, [IsExpanded]);   

    const closeConfirmDownload = () => {
      setOpenConfirmDownload(false);
    }

    const closeDayDialogDetail = (row) => {
      setOpenDayDialog(false);
      setDialogDate(null);
    };
    
    const handleDownloadData = (event) => {
      setToDownload([])
      setDownloadType(event.target.value)
      // download data for all selected rows
      selected.forEach((row) => {
        (async()=>{
          var from = TimeUtils.getBeginningOfDay(row.date); // added
          var to = TimeUtils.getEndingOfDay(row.date); // added
          let requestDate=row.date.toISOString()
          await downloadReportData(id,requestDate,from,to).then(data => {
            data.id = row.id
            data.date = row.date
            setToDownload((oldState) => [...oldState, data])
          }).catch((err) => {
            console.error(err);
          })
        })();

      })
      setOpenConfirmDownload(true)
    };
  
    const handleSetSelected = (newSelected) => {
      setSelected(newSelected)
    }
  
  
    const getReportAndSessionData = async () => {
      let from = TimeUtils.localToUtc(dataFrom)
      let to = TimeUtils.localToUtc(dataTo)
      let activeDaysResult = await SessionService.getActiveDaysByPatientId(
        id,
        from,
        to
      ).catch((err) => {
          console.error(err);
      });
      setReportDays(activeDaysResult?.data.reverse())  
    }

    const handleOpenDayDialog = (date) => {
      setOpenDayDialog(true);
      setDialogDate(date);
    }
  
    let rows = [];
    reportDays?.forEach((day) => {
      let reportDate = new Date(day.start);
      let day_information = {
        date: reportDate,
      };
      rows.push(day_information)
    });
  
    return (
    <Box>
        <DayDataDialog
            date={dialogDate}
            open={openDayDialog}
            onClose={closeDayDialogDetail}
            patientId={id}
        />

        <MultipleDayDialog
            dates={multipleDaySpan}
            open={openMultipleDayDialog}
            onClose={closeMultipleDayDialog}
            patientId={id}
        />

        <Accordion sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "1000px" }, maxWidth: {md: "1000px"}, mt: 2 }} onChange={(event, expanded) => setIsExpanded(expanded) }>
            <AccordionSummary
            expandIcon={<GridExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography variant="h5"><Translate value="DataArchive" /></Typography>
    
            </AccordionSummary>
            <AccordionDetails>

            <Typography variant="h6"><Translate value="DailyReportsAndSessions"/></Typography>
            <Typography variant="h7"><Translate value="DailyReportsAndSessionsDescription"/></Typography>
    
            <Box sx={{display:"flex", flexDirection:"row", justifyContent: 'space-between', alignItems: 'flex-end'}}>
            <FromToPickerDate
                from={dataFrom}
                to={dataTo}
                setFromValue={setDataFrom}
                setToValue={setDataTo}
                />
            <Box>
                <Button value="pdf" onClick={handleDownloadData} variant="contained" color="primary" disabled={!selected?.length>0} sx={{ minWidth:"200px", height:"40px", mb:1}}>
                <Translate value="DownloadPdf"/> {!selected?.length>0? "": "(" + selected?.length.toString() + ")"}
                </Button>
                <Button value="csv" onClick={handleDownloadData} variant="contained" color="primary" disabled={!selected?.length>0} sx={{ minWidth:"200px", height:"40px", mb:1, ml:2}}>
                <Translate value="DownloadCsv"/> {!selected?.length>0? "": "(" + selected?.length.toString() + ")"}
                </Button>
            </Box>
            </Box>
    
            {(reportDays.length > 0)?
            <DataTabGrid 
                patientId={id}
                handleSetSelected={handleSetSelected} 
                selected={selected} 
                rows={rows} 
                openDayDialogDetail={handleOpenDayDialog} 
                setSessionClicked={props.setSessionClicked}
                />
            :
            <Box sx={{mt:3}}>
                <Typography variant="h7"><Translate value="NoData"/></Typography>
            </Box>}
    
            <Confirmdownload
                dataForFiles={toDownload}
                fileType={downloadType}
                patientId={id}
                open={openConfirmDownload}
                onClose={closeConfirmDownload}
                numberOfFiles={selected.length}
            />

            <Divider sx={{height:"20px",mr:2}} orientation="horizontal" flexItem />

            {/* Multi day report */}
            <Box sx={{mt:3}}>
              {/* <MultipleDayDialog
              dates={multidaySpan}
              // reportData={reportData}
              open={multiDayDialogOpen}
              // onDataUpdateRequest={()=>openDayDialogDetail(dialogDate)}
              onClose={()=>setReportDialogOpen(false)}
              // onDownloadReport={()=>{
              //   setReportDialogOpen(true)}}
              // detailedSessions={detailedSessions}
              patientId={id}
              /> */}

              <Typography variant="h6"><Translate value="MultipleDayReport"/></Typography>
              <Typography variant="h7"><Translate value="MultipleDayReportDescription"/></Typography>

              <Box sx={{display:"flex", flexDirection:"row", justifyContent: 'space-between', alignItems: 'flex-end'}}>
                <FromToPickerDate
                    from={multipleDayFrom}
                    to={multipleDayTo}
                    setFromValue={setMultipleDayFrom}
                    setToValue={setMultipleDayTo} // KAC TO DO: datepicker should default to last 5 days // datepicker should have a max date span of 5 days
                    // if a single day is selected then display: "For reports of a single day, consider using the daily reports above."
                    // set button to be disabled if there is no data for the selected days
                    // create handleDownloadMultiday method
                    // should there be a preview of multiday report before downloading? - so there is only download button inside the report?
                    // consider making multiple days report an expandable tab inside the data archive tab, to make the data archive tab less cluttered
                  />

                  <Button onClick={handleMultipleDayDialogOpen} variant="contained" color="primary" sx={{ minWidth:"200px", height:"40px", mb:1}}>
                    <Translate value="GenerateReport"/>
                  </Button>
                {/* <Box>
                  <Button value="pdf" onClick={handleDownloadMultiday} variant="contained" color="primary" sx={{ minWidth:"200px", height:"40px", mb:1}}>
                    <Translate value="DownloadPdf"/>
                  </Button>
                  <Button value="csv" onClick={handleDownloadMultiday} variant="contained" color="primary" sx={{ minWidth:"200px", height:"40px", mb:1, ml:2}}>
                  <Translate value="DownloadCsv"/>
                  </Button>
                </Box> */}

              </Box>

              {/* {multipleDayDataLoading?  <CircularProgress /> : null}

              <Typography variant="h7"><Translate value="Note: One or more of the selected days does not contain any detailed data."/></Typography> */}

              <Box sx={{mt:2, display:'flex', flexDirection: 'row', mx:-2}}>
                {createMultipleDays(multipleDaySpan)}
              </Box>

            </Box>


            </AccordionDetails>
        </Accordion>
      </Box>
    );
  }
  
  const DataTabGrid = (props) => {
    const patientId = props.patientId;
    const handleSetSelected = props.handleSetSelected
    let selected = props.selected;
    let numSelected = selected.length;
    let rows = props.rows;
    let rowCount = rows.length;
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = rows.map((row, index) => {
          row.id = index;
          return row;
        });
        handleSetSelected(newSelected);
        return;
      }
      handleSetSelected([]);
    };
  
    const handleClick = (event, row) => {
      const selectedIndex = selected.find((element) => element.id === row.id);
      let newSelected = [];
      if(selectedIndex){
        newSelected = selected.filter((element) => element.id !== row.id);
      }else{
        newSelected = [...selected, row];
      }
      handleSetSelected(newSelected)
    };
    
    return (
      <div  style={{ maxHeight: "500px", overflow: "auto" }}>
      <Table>
        <caption> <Translate value="DataTableDescription"/></caption>
        <colgroup>
          <col style={{width:'10%'}}/>
          <col style={{width:'35%'}}/>
          <col style={{width:'45%'}}/>
        </colgroup>
      <TableHead>
        <TableRow>
          <TableCell> </TableCell>
          <TableCell> </TableCell>
          <TableCell> </TableCell>
          <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={handleSelectAllClick}
            inputProps={{
              'aria-label': 'select all',
            }}
          />
        </TableCell>
        </TableRow>
      </TableHead>
  
      <TableBody>
          {rows.map((row, index) => (
            <DailyReportRow 
              key={row.name} 
              patientId={patientId}
              row={row} 
              id={index} 
              selected={selected} 
              handleClick={handleClick} 
              openDayDialogDetail={props.openDayDialogDetail} 
              setSessionClicked={props.setSessionClicked}/>
          ))}
        </TableBody>
      </Table>
      </div>
    );
  };
  
  const DailyReportRow = (props) => {
    const patientId = props.patientId;
    const row = props.row;
    const selected = props.selected;
    const openDayDialogDetail = props.openDayDialogDetail;
    const setSessionClicked = props.setSessionClicked;
    const id = props.id;

    const [sessions, setSessions] = useState([]);
    
    const [openRow, setOpen] = useState(false);
  
    const labelId = `enhanced-table-checkbox-${id}`;
  
    const isSelected = () => selected.find((element) => element.id === props.id) !== undefined;
    const isItemSelected = isSelected(id);
    
    const handleSelected = (event, id, row) => {
      row.id = id;
      props.handleClick(event, row);
    }

    useEffect(() => {
      if(openRow === true){
        getSessions()
      }else{
        setSessions([])
      }
    },[openRow])

    const getSessions = async () => {
      await SessionService.getSessionOverviewByPatientId(
        patientId,
        TimeUtils.getBeginningOfDay(row.date).toUTCString(),
        TimeUtils.getEndingOfDay(row.date).toUTCString(),
      ).then((response) => {
        setSessions(response.data)
      }).catch((err) => {
        console.log(err)
      })
    }
  
    return (
      <Fragment>
        <TableRow sx={{cursor: 'pointer', '& > *': { borderBottom: 'unset' }}} hover>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!openRow)}
            >
              {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row" onClick={(event) => openDayDialogDetail(row.date)}>
          <Translate value="DailyReport" />
          </TableCell>
          <TableCell align="left" onClick={(event) => openDayDialogDetail(row.date)}>{TimeUtils.formatDateYYMMDD(row.date)}</TableCell>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              onClick={(event) => handleSelected(event, id, row)}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={openRow} timeout="auto">
              <Box sx={{ margin: 1, ml:10 }}>
                <Table size="small" aria-label="purchases">
                <colgroup>
                  <col style={{width:'20%'}}/>
                  <col style={{width:'30%'}}/>
                </colgroup>
                  <TableBody>
                    {sessions?.length <= 0  ?
                      <CircularProgress size={24}/>
                      :
                      sessions.map((sessionRow) => (
                        <TableRow key={sessionRow.date} hover sx={{ cursor: 'pointer' }} onClick={(event) => setSessionClicked(sessionRow)}>
                          <TableCell component="th" scope="row">
                            <Translate value="Session" /> {sessionRow.id}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {TimeUtils.formatDateHHmm(TimeUtils.utcToLocal(sessionRow.parts?.start?.timestamp))}
                          </TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
  
  DailyReportRow.propTypes = {
  row: PropTypes.shape({
      date: PropTypes.string.isRequired,
      sessions: PropTypes.arrayOf(
      PropTypes.shape({
          amount: PropTypes.number.isRequired,
          customerId: PropTypes.string.isRequired,
          date: PropTypes.string.isRequired,
          id: PropTypes.string.isRequired,
      }),
      ).isRequired,
  }).isRequired,
};

export const downloadReportData = async(id,date,from,to) => {
  return await SessionService.getDayData(
    id,
    date,
    TimeUtils.localToUtc(from),
    TimeUtils.localToUtc(to)
    ).then((response) => {
      return response.data;
    }).catch((err) => {
      throw err;
  });
}

  
export default DataTab
import React, { useState, useLayoutEffect, forwardRef } from 'react'
import { Box } from '@mui/system'
import DownloadIcon from '@mui/icons-material/Download';
import { Dialog, Typography, Button, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { PatientDetailText } from './PatientDetailText'
import { translate, Translate, Localize } from 'react-i18nify';
import TimeUtils from '../../utils/TimeUtils';
import { useRef, useEffect } from 'react';
import { Chart } from '../Session/DetailedSessionChartUplot';
import { MetadataService, SessionService } from '../../services/DataService';
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import TreatmentControls from '../Treatment/TreatmentControls';
import CircularProgress from '@mui/material/CircularProgress';
import { GenerateTreatmentInfo } from '../../pages/Patient/GenerateTreatmentInfo';
import { DailyReportDownloadCsv } from "../../components/Patient/DailyReportDownloadCsv";
import { O2maticLogoBlack } from '../Layout/O2maticLogoBlack';
import { LogService } from '../../services/DataService';

// const generateChartData = (data) => {
//     try {
//         const chartLength = data.reduce((total, batch) => total + batch.values.length, 0);
//         const timestamp = Array.from({ length: chartLength }, () => null);
//         const spo2Data = Array.from({ length: chartLength }, () => null);
//         const pulseData = Array.from({ length: chartLength }, () => null);
//         const o2Data = Array.from({ length: chartLength }, () => null);

//         let currentIndex = 0;

//         for (const batch of data) {
//             const values = batch.values;
//             const batchStartTime = new Date(TimeUtils.utcToLocal(batch.start, true)).getTime() / 1000;

//             timestamp[currentIndex] = batchStartTime;
//             spo2Data[currentIndex] = null;
//             pulseData[currentIndex] = null;
//             o2Data[currentIndex] = null;

//             for (const value of values) {
//                 const timestampDataPoint = new Date(TimeUtils.utcToLocal(value.timestamp, true)).getTime() / 1000;

//                 timestamp[currentIndex] = timestampDataPoint;
//                 spo2Data[currentIndex] = value.spO2;
//                 pulseData[currentIndex] = value.pulse;
//                 o2Data[currentIndex] = value.o2;

//                 currentIndex++;
//             }

//             const batchEndTime = new Date(TimeUtils.utcToLocal(batch.end, true)).getTime() / 1000;

//             timestamp[currentIndex] = batchEndTime;
//             spo2Data[currentIndex] = null;
//             pulseData[currentIndex] = null;
//             o2Data[currentIndex] = null;

//             currentIndex++;
//         }

//         const result = [timestamp, spo2Data, pulseData, o2Data];

//         return result;
//     } catch (error) {
//         throw new Error('Error generating chart data: ' + error);
//     }
// };



const PrintContent = forwardRef((props, ref) => {
    return (
        <Box
            sx={{
                m: 0,
                p: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
            }}
            ref={ref}
        >
            {props.children}
        </Box>
    );
});

export const MultipleDayReport = (props) => {

    const printElementRef = useRef(null);
    let forwardRef = props.forwardRef;

    let dates = props.dates
    let chartData = props.chartData
    let patientId = props.patientId
    
    // let data = props.data
    // let detailedSessionData = props.data?.detailedSessions
    // let reportData = props.reportData
    // let spo2Summary = data?.spO2Summary
    // let o2Summary = data?.o2Summary
    // let pulseSummary = data?.pulseSummary
    // let patient = data?.patient?.patient
    // let sessions = data?.sessions
    // let treatments = data?.treatments
    // let duration = props.data?.numberOfSamples
    let width = "1080"
    let xaxisRange = "compact"

    if (!props.open) {
        return null;
    }

    
    const generatePDF = () => {
        const printElement = printElementRef.current;
        var opt = {
            margin: [0, 0, 0, 0],
            filename: "O2matic - " + translate("Patient") + " " + patientId + " - " + translate("MultipleDayReport") + " " + TimeUtils.formatDateYYMMDD(dates[0]) + "-" + TimeUtils.formatDateYYMMDD(dates[dates.length -1]) + ".pdf",
            image: { type: "jpeg", quality: 1 },
            pagebreak: { mode: "css" },
            html2canvas: {
                scale: 2,
                letterRendering: true,
                useCORS: true,
            },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
            noPdfOpenParams: true,
        };

        html2pdf().set(opt).from(printElement).save();
        (async () => {
            await LogService.saveLog([TimeUtils.formatDateYYMMDD(dates[0]),TimeUtils.formatDateYYMMDD(dates[dates.length-1])], translate("MultipleDayReport"), "pdf", patientId).catch((error) => {
                console.error("Error saving log: ", error);
            })
        })();
    };    
    if (!props.open) {
        return null;
    }

    const IndividualCharts = (props) => {

        let dates = props.dates
        let chartData = props.chartData
        return dates.map((day,index) => {
            // get beginning and end of day and dont convert to UTC, cause they have already been converted when made into chart data

            const from = TimeUtils.getBeginningOfDay(new Date(day));
            const to = TimeUtils.getEndingOfDay(new Date(day));
            
            // extract data for this day
            const fromIndex = chartData[0].findIndex(value => value > (new Date(from).getTime() / 1000));
            const toIndex = chartData[0].findIndex(value => value > (new Date(to).getTime() / 1000 + 59))-1;

            let dayData = [chartData[0].slice(fromIndex, toIndex), chartData[1].slice(fromIndex, toIndex), chartData[2].slice(fromIndex, toIndex), chartData[3].slice(fromIndex, toIndex)]

              return ( <Box sx={{pageBreakBefore: "always", width:"100%", mx:0, display:'flex', justifyContent: 'center', flexDirection: 'column', }}>
                
                <Box sx={{display:'flex', mt:5, justifyContent: 'space-between'}}>
                  <Typography variant="h5">{translate("Day")} {index+1}</Typography>
                  <Typography key={day} variant="h5">{translate(day.toLocaleTimeString('en-us', {weekday:'long'}).split(' ')[0])}</Typography>
                  <Typography key={day} variant="h5">{TimeUtils.formatDateYYMMDD(day)}</Typography>
                </Box>

                <Box sx={{display:'flex', justifyContent: 'center'}}>
                    {dayData?.length == 0 ? "No chart data" : 
                    <Chart width={width} data={dayData} xaxisRange={xaxisRange}/>
                }
                </Box>

                
              </Box>
              
              )
          })
    
    }


    return (

        <Box
            maxWidth="800px"
            sx={{ width: "100%", p: 0 }}

        >
            { false?
            <Box sx={{ display: 'flex', justifyContent: 'center', height:"250px", alignItems: 'center' }}>
                <CircularProgress />
            </Box> 
            :
            <>
                <Box sx={{m:2, position:"absolute",top:65,right:20, display:"flex", flexDirection:"row"}}>
                    <Button sx={{mr:2}} variant="contained" onClick={() => generatePDF()} >{translate("DownloadPdf")} </Button>
                    {/* <Button sx={{ alignSelf: "flex-end" }} variant="contained" onClick={() => DailyReportDownloadCsv(reportData,date)} >{translate("DownloadCsv")} </Button> */}
                </Box>
                
                <Box sx={{ p: 1 }} ref={forwardRef}>
                <Box sx={{ p: 1 }} ref={printElementRef}>

                    <Box sx={{position:"absolute",top:15,right:0}}>
                        <O2maticLogoBlack width={"200px"}/>
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography variant='h4'><Translate value="MultipleDayReport"/></Typography>
                        <Typography variant='h5'> <Translate value="Dates"/>: {TimeUtils.formatDateYYMMDD(new Date(props.dates[0]))} - {TimeUtils.formatDateYYMMDD(new Date(props.dates[props.dates.length-1]))}</Typography>
                    </Box>

                    <Box sx={{ mt: 1, display: "flex", flexDirection: "column" }}>
                        <Typography variant='h6'><Translate value="PatientO2maticId"/>: {patientId}</Typography>
                    </Box>
                    <Box sx={{ mb:2, display:"flex", flexDirection:"column"}}>
                        {/* <Typography variant='body'><Translate value={"LengthOfRecordedData"}/>: {TimeUtils.secondsToHMS(duration)}</Typography> */}
                        <Typography variant='body'><Translate value="ReportGenerated"/>: {TimeUtils.formatDate(new Date())}</Typography>
                    </Box>

                    <Box sx={{ mx:-1, pl:1, width: "100%", mb:10}}>
                        {
                            chartData?.length == null ? "No chart data" :
                            <>{
                            chartData?.length == 0 ? <CircularProgress/> : // should maybe do some loading here instead?
                                <Chart width={width} data={chartData} xaxisRange={xaxisRange}/>
                            }</>
                        }
                    </Box>

                    <Box sx={{ mx:-1, pl:1, width: "100%", mb:10}}>
                    {
                        chartData?.length == null ? "No chart data" :
                        <>{
                        chartData?.length == 0 ? <CircularProgress/> : // should maybe do some loading here instead?
                        <IndividualCharts dates={dates} chartData={chartData}/>
                        }</>
                    }
                    </Box>
                    

                </Box>
                </Box>
            </>
            }
        </Box >
    )
}


import React, { useState } from 'react'
import { Box } from '@mui/system'
import { Dialog, Typography,} from '@mui/material'
import TimeUtils from '../../utils/TimeUtils';
import { useRef, useEffect } from 'react';
import { SessionService } from '../../services/DataService';
import { MultipleDayReport } from "../../components/Patient/MultipleDayReport";
import CircularProgress from '@mui/material/CircularProgress';
import html2pdf from "html2pdf.js/dist/html2pdf.min";
import AlertBox from '../AlertBox';
import {  translate } from 'react-i18nify';
import { downloadReportData } from './Datatab';



const generateChartData = (data) => {
    try {
        const chartLength = data.reduce((total, batch) => total + batch.values.length, 0);
        const timestamp = Array.from({ length: chartLength }, () => null);
        const spo2Data = Array.from({ length: chartLength }, () => null);
        const pulseData = Array.from({ length: chartLength }, () => null);
        const o2Data = Array.from({ length: chartLength }, () => null);

        let currentIndex = 0;

        for (const batch of data) {
            const values = batch.values;
            const batchStartTime = new Date(TimeUtils.utcToLocal(batch.start, true)).getTime() / 1000;

            timestamp[currentIndex] = batchStartTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            for (const value of values) {
                const timestampDataPoint = new Date(TimeUtils.utcToLocal(value.timestamp, true)).getTime() / 1000;

                timestamp[currentIndex] = timestampDataPoint;
                spo2Data[currentIndex] = value.spO2;
                pulseData[currentIndex] = value.pulse;
                o2Data[currentIndex] = value.o2;

                currentIndex++;
            }

            const batchEndTime = new Date(TimeUtils.utcToLocal(batch.end, true)).getTime() / 1000;

            timestamp[currentIndex] = batchEndTime;
            spo2Data[currentIndex] = null;
            pulseData[currentIndex] = null;
            o2Data[currentIndex] = null;

            currentIndex++;
        }

        const result = [timestamp, spo2Data, pulseData, o2Data];

        return result;
    } catch (error) {
        throw new Error('Error generating chart data: ' + error);
    }
};

export const MultipleDayDialog = (props) => {

    const DetailDialogContainerRef = useRef(null);
    const dates = props.dates;
    const patientId = props.patientId;
    //const [detailedData, setDetailedData] = useState(null);
    const [width, setWidth] = useState(300);
    const [chartLoading, setChartLoading] = useState(true);
    //const [xaxisRange, setXaxisrange] = useState("all");
    const [chartData, setChartData] = useState(null);
    const [noDetailedData, setNoDetailedData] = useState(false)

    const [alertBox, setAlertBox] = useState({
        "visiblity": false,
        "statusCode": null,
        "text": null,
        "error": null
    }
    );


    const handleAlertBox = (visiblity, statusCode, text, error) => {
        setAlertBox({
            "visiblity": visiblity,
            "statusCode": statusCode,
            "text": text,
            "error": error
        }
        )
    }
    const dismissAlertBox=()=>{
        setAlertBox({
            "visiblity": false,
            "statusCode": null,
            "text": null,
            "error": null

        })
    }

    const handleEntered = () => {
        if (DetailDialogContainerRef.current) {
            setWidth(DetailDialogContainerRef.current.clientWidth);
        }
    };

    const getDetailedSessionsByPatientId = () => {
        // setChartLoading(true)
        var from = TimeUtils.getBeginningOfDay(dates[0]); // beginning of first day in multiple day span
        var to = TimeUtils.getEndingOfDay(dates[dates.length -1]); // end of last day in multiple day span
        SessionService.getDetailedSessionsByPatientId(
            patientId,
            TimeUtils.localToUtc(from),
            TimeUtils.localToUtc(to)
        ).then((response) => {
            if(response.data === null){
                setChartLoading(false)
                setNoDetailedData(true)
                return
            }
            
            const orderedData = response.data.sort((a, b) => new Date(a.start) - new Date(b.start));
            const data = generateChartData(orderedData)
            setChartData(data)
            setChartLoading(false)
            // setDetailedSessions(response.data);  
        }).catch((error) => {
            console.error("Error getting detailed sessions", error); //add alert box
            handleAlertBox(true, error.response.status, translate("NoDetailedSessionData"), null)
            setChartLoading(false)
            setNoDetailedData(true)
        })

    }
    useEffect(() => {
        setChartData([]);
        setNoDetailedData(false)
        //setChartLoading(true)
        if(props.open){
            // (async () => {
            //     const from = TimeUtils.getBeginningOfDay(date)
            //     const to = TimeUtils.getEndingOfDay(date)
            //     const reportData = await downloadReportData(patientId, date.toISOString(), from, to)
            //     .catch((error) => {
            //         console.error("Error getting report data:", error.response.data);
            //         handleAlertBox(true, error.response.status, translate("NoDetailedSessionData"), null)
            //         setChartLoading(false)
            //         setNoDetailedData(true)
            //     });
            //     //setReportData(reportData);
            //   })();    
            getDetailedSessionsByPatientId(patientId, dates)
        }
    }, [props.open])

    if (!props.open) {
        return null;
    }

    return (

        <Dialog
            fullWidth
            maxWidth="md"
            sx={{ width: "100%", p: 0 }}
            open={props.open}
            onClose={() => { props.onClose() }}
            TransitionProps={{ onEntered: handleEntered }}
        >

            <Box sx={{ p: 2 }}>
                {
                    chartLoading ? 
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', height:"250px", alignItems: 'center' }}>
                        <CircularProgress />
                    </Box> :

                    noDetailedData ?
                    <Box sx={{width: "max-content"}} >
                        <AlertBox 
                            visible={alertBox.visiblity} 
                            error={alertBox.error}
                            text={alertBox.text}
                            statusCode={alertBox.statusCode}
                        />
                    </Box>
                    :
                    <MultipleDayReport
                        open={true}
                        dates={dates}
                        chartData={chartData}
                        patientId={patientId}

                        // reportData={reportData}
                        // data={reportData}
                    ></MultipleDayReport>
                    }
                

            </Box>
        </Dialog >
    )
}

const English = {
  en: {

    // O2MATIC ADMINISTRATOR
    "O2maticAdministrator": "O2matic Administrator",
    "ReturnToO2maticAdministrator": "Return to O2matic administrator",
    "ReturnToAdminpage": "Return to adminpage",
              // oximeter
    "AddOximeter": "Add pulse oximeter",
    "AddOximeterToWhitelist": "Add pulse oximeter to whitelist",
    "AddAnotherOximeter": "Add another pulse oximeter",
              // metadata
    "Metadata": "Metadata",
    "AddMetadata": "Add metadata",
    "AddMoreMetadata": "Add more metadata",
    "SelectMetadata": "Select metadata",
    "MetadataValue": "Metadata value",
    "MetadataValueHasBeenAddded": "Metadata value has been addded",
    "AddMetadataValue": "Add metadata value",
    "CreateMetadataValue": "Create metadata value",
    "MetadataId": "Metadata ID",
    "MetadataValueId": "Metadata value ID",
              // notification
    "Notification": "Notification",
    "AddNotification": "Add notification",
    "AddNotificationText": "Add a notification here that will be displayed to all users.",
    "WriteMessageHere": "Write message here",
    "NotificationActive": "Notification active",


    // PATIENT PAGE
    "Patient":"Patient",
    "Patients": "Patients",
    "PatientDashboard": "Patient dashboard",
    "PatientDashboardText": "Here you can get an overview of measurements taken and treatments given by the patient's device.",
    "PatientLookup": "Patient lookup",
    "PatientSearch": "Search for patients",
    "PatientLookupExplanation": "Search for patients using one or more of the search critiria below.",
    "PatientLookupExplanation2": "Click on a patient in the table to open the patient page.",
    "AddPatient": "Add patient",
    "AddPatientText": "Here you can create new patient. All fields marked with * are mandatory.",
    "EditPatient": "Edit patient",
    "EditPatientText": "Here you can edit a patient. All fields marked with * are mandatory.",
    "DeletePatientText": "Are you sure you want to delete this patient? All patient data will be lost.",
    "PatientData": "Patient data",
    "PatientId": "Patient ID",
    "PatientO2maticId": "Patient O2matic ID",
    "PatientInformation": "Patient information",
    "PersonalInformation": "Personal information",
    "Birthdate": "Birthdate",
    "DateOfBirth": "Date of birth",
    "Address": "Address",
    "AddressLine": "Street name, no",
    "PostalCode": "Postal code",
    "City": "City",
    "Country": "Country",
    "Language": "Language",
    "LastName": "Last name",
    "FirstName": "First name",
    "Gender": "Gender",
    "Female": "Female",
    "female": "Female",
    "Male": "Male",
    "male": "Male",
    "Contacts": "Contacts",
    "PhoneNumber": "Phone number",
    "HealthcareProvider": "Healthcare provider",
    "HealthcareProviderName": "Healthcare provider name",
    "ServiceProvider": "Service provider",
    "ServiceProviderName": "Service provider name",
    "Installer": "Installer",
    "CustomIdentifier": "Custom identifier",
    "Identifier": "Identifier",
    "IdentifierType": "Identifier type",
    "IdentifierValue": "Identifier value",
    "locationsDoNotMatch": "locations do not match",
    "Prescribed": "Prescribed",
    "Installed": "Installed",
    "DeviceAssignedToPatient": "Device assigned to patient",
    "LatestReceivedDeviceLog": "Latest received device log",
    "LastSession": "Last session",
    "LastSessionTime": "Last session time",
    "NextSessionTime": "Next session time",
    "Pending": "Pending",
    "PendingInstallation": "Pending installation",
    "PreviousTreatmentInformation": "Previous treatment information",
    "CurrentTreatmentOnDevice": "Current treatment on device:",
    "CurrentTreatmentOnDeviceDescription": "The recently prescribed treatment has not yet been installed on the patient's device. The current treatment profile on the patient's device is shown below. In order for the new treatment to be installed on the patient's device, please inform the service provider that the treatment should be updated on the next visit to the patient.",
    "InstallationOfTreatmentPending": "Installation of treatment pending",
    "Compliance":"Compliance",
    "PatientLog":"Patient log",
    "O2maticId": "O2matic ID",
    "SelectLanguage":" Select language",
    "SelectCountry": "Select country",
    "PatientNotAssignedToDevice": "Patient is currently not assigned to any device",
    "SelectSessionExplanation": "Click on a specific session in the table to see detailed data.",
    "SelectLocationFirst":"Select location first",
    "SelectTreatment": "Select treatment profile",
                  // data and charts
    "ShowDataFor:": "Show data for:",
    "DataArchive": "Data archive",
    "DataTableDescription": "Click on a row to open file or use checkboxes to select files to download.",
    "NewPreviewChartDescription1": "The chart shows an overview of the 50 sessions at each page of the table above. To change which 50 sessions are viewed, use the arrows in the bottom of the table to change the page.",
    "NewPreviewChartDescription2": "The red lines indicate the SpO2 target range of the treatment profile, while an orange dot indicates a change of treatment profile.",
    "DetailedDataDescription": "Click and drag cursor to zoom in. Double-click to return to full view.",
    "DetailedData": "Detailed data",
    "DetailedSessionData": "Detailed session data",
    "NoSessionInSelectedInterval": "No sessions in selected interval",
    "ChartHelp": "ChartHelp", //UPDATE
    "ChartPreview": "ChartPreview", //UPDATE
    "SessionsOverview": "Sessions overview",
    "TreatmentChanged": "Treatment changed",
    "SpO2InAdjustingPhase": "Average SpO2 in adjusting phase",
    "TargetO2FlowAtEndOfSession": "Target O2 flow at end of session",
    "ViewLiveData": "View live data",
    "EnableLiveData":"Enable live data",
    "PauseLiveData":"Pause live data",
    "DisableLiveData":"Disable live data",
    "Last7days": "Last 7 days",
    "Last14days": "Last 14 days",
    "Help": "Help",
                  // reports and sessions
    "DailyReport": "Daily report",
    "NoDetailedSessionData": "Unable to fetch day data",
    "MultipleDayReport": "Multiple day report",
    "MultipleDayReportDescription": "Use date pickers to select a span of maximum 5 days to generate a multiple day report.",
    "DailyReportsAndSessions": "Daily reports and sessions",
    "DailyReportsAndSessionsDescription": "Here you can find daily reports and sessions within the selected time frame.",
    "CreateReport":"Create report",
    "ReportCreated": "Report created",
    "Sessions":"Sessions",
    "ReportGenerated": "Report generated",
    "DownloadAllSessionsAsCsv": "Download all sessions as csv",
    "SessionInformation": "Session information",
    "NextSession":"Next session",
    "UntilNextSession": "Until next session",
    "NextIntervention": "Next intervention",
    "TimeSpent":"Time spent",
    "TimeSpent(%)":"Time spent (%)",
    "LengthOfRecordedData":"Length of recorded data",
    "GenerateReport":"Generate report",
    "Adjusting":"Adjusting",
    "DownloadReport":"Download report",
    "DaySummary":"O2matic day summary",
    "DaySummaryDisclaimer":"Disclaimer: The calculations used to generate this report are not validated and should not be used to evalutate the patient's condition",
    "DetailedDataDisclaimerChart": "Detailed data in this chart are not validated, and not intended for clinical decision making. Please consult Session data before making clinical decisions.",
    "DetailedDataDisclaimerTable": "Detailed data statistics in this table are not validated, and not intended for clinical decision making. Please consult Session data before making clinical decisions.",
    "DetailedDataDisclaimerTableCsv": "Detailed data statistics are not validated, and not intended for clinical decision making. Please consult Session data before making clinical decisions.",
    "DisclaimerDataVal": "Disclaimer: Data are not validated and should not be used to evalute a patient's condition.",
    "ImportantNote": "Important Note",
    "DetailedDataStatistics":"Detailed data statistics",
    "DetailedDataStatisticsByTreatmentProfile":"Detailed data statistics by treatment profile",
    "SummaryDate":"Summary date",
    "DataByTreatmentProfile":"Data by treatment profile",
    "Session":"Session",
    "SessionId":"Session ID",
    "LengthOfSession":"Length of session",
    "InitialValues": "Initial values",
    "Start":"Start",
    "StartOfSession":"Start of session",
    "End":"End",
    "EndOfSession":"End of session",
    "Zone":"Zone",
    "Analysing":"Analysing",
    "Field1": "Field 1",
    "Field2": "Field 2",
                  // SpO2, pulse and O2 flow
    "SpO2": "SpO2",
    "SpO2TargetRange": "SpO2 target range",
    "SpO2RangeMax": "Upper SpO2 target range",
    "SpO2RangeMin": "Lower SpO2 target range",
    "SpO2Average": "SpO2 average",
    "SpO2Std": "SpO2 std",
    "SpO2StandardDeviation": "SpO2 standard deviation",
    "SpO2Min": "SpO2 minimum",
    "SpO2Max": "SpO2 maximum",
    "SpO2Initial":"SpO2 initial",
    "InitialSpO2": "Initial SpO2",
    "LastMeasuredSpO2": "Last measured SpO2",
    "PulseBpm": "Pulse (bpm)",
    "PulseRange": "Pulse range",
    "PulseAverage": "Pulse average",
    "PulseStd": "Pulse std",
    "PulseStandardDeviation": "Pulse standard deviation",
    "PulseMin": "Pulse minimum",
    "PulseMax": "Pulse maximum",
    "PulseHigh": "Pulse High",
    "PulseInitial":"Pulse initial",
    "InitialPulse": "Initial pulse",
    "LastMeasuredPulse": "Last measured pulse",
    "O2": "O2",
    "O2Flow": "O2 flow",
    "O2FlowRange": "O2 flow range",
    "O2FlowAverage": "O2 flow average",
    "O2FlowStd": "O2 flow std",
    "O2FlowStandardDeviation": "O2 flow standard deviation",
    "O2FlowMin": "O2 flow minimum",
    "O2FlowMax": "O2 flow maximum",
    "O2FlowTarget":"O2 flow target",
    "O2FlowInitial":"O2 flow initial",
    "InitialO2Flow": "Initial O2 flow",
    "LastMeasuredO2Flow": "Last measured O2 flow",
                  // compliance
    "ofComplianceGoal": "of compliance goal",
    "ComplianceGoalLegendPercent": "Percentage of daily oxygen compliance goal reached:",
    "ComplianceGoalLegendHours": "Number of hours of daily active use of oxygen:",
    "HoursOfUseUnverified": "Hours of use unverified",
    "2HoursOfUnverifiedUse": "2 hours of unverified use",
    "2HoursOfVerifiedUse": "2 hours of verified use",

    // TREATMENT
    "Treatment": "Treatment",
    "Treatments": "Treatments",
    "TreatmentProfile": "Treatment profile",
    "TreatmentProfiles":"Treatment profiles",
    "TreatmentProfileDetails": "Treatment profile details",
    "TreatmentId": "Treatment ID",
    "TreatmentInformation": "Treatment information",
    "TreatmentName": "Treatment name",
    "ProfileName": "Profile name",
    "ProfileSettings": "Profile Settings",
    "AddTreatmentProfile": "Add treatment profile",
    "AddTreatmentProfileText": "Here you can create new treatment profile. All fields marked with * are mandatory.",
    "DisableTreatmentProfileText": "Do you really want to disable this treatment profile?",
    "EnableTreatmentProfileText": "Do you really want to enable this treatment profile?",
    "ConsiderTheFolowing": "Consider the following",
    "QuestionType2RespiratoryFailure": "Is the patient in risk of type 2 respiratory failure?",
    "QuestionLevelOfActivity": "What is the patient's level of activity?",
    "QuestionOxygenSource": "How much oxygen is the oxygen source able to provide?",
    "TemplateId": "Template ID",
    "Customize": "Customize",
    "Customizing": "Customizing",
                  // analysing phase
    "AnalysingPhase": "Analysing phase",
    "AnalysingPhaseDuration(s)": "Analysing phase duration (s)",
    "AnalysingPhaseExplanationPatientPage": "If Analysing Phase is set to Yes, the patient will continue to receive a fixed O2 flow for the first 5 minutes after putting on the pulse oximeter.",
    "AnalysingPhaseExplanationTreatmentPage": "Set to yes if the patient must receive a fixed O2 flow the first 5 minutes after putting on the pulse oximeter.",
    "AnalysingPhaseDescription": "First 5 min of session with fixed O2 flow",
    "AnalysingPhaseExplanation": "Set to yes if you want the first 5 minutes of a session to have a fixed O2 flow",
    "DefineFixedFlow": "First five minutes of session with fixed flow",
    "DefineFixedFlowMessage": "The first fime minutes of each session will begin with five minutes of fixed O2 flow. Afterwards the device will begin to adjust the flow.",
                  // adjusting phase
    "AdjustingPhase": "Adjusting phase",
                  // flow response
    "FlowResponse": "Flow response",
    "FlowResponseRate": "Flow response rate",
    "FlowResponseExplanation": "How fast the device changes the flow in adjusting phase",
    "FlowResponseExplanation": "The flow response determines how quickly the flow of oxygen is adjusted up or down by the device.",
    "DefineFlowResponse": "Select the appropriate flow response",
    "DefineFlowResponseMessage": "The flow response setting changes how fast the device adjusts the O2 flow based on the patient's vitals",
    "Fast": "Fast",
    "AlwaysOn": "Always on",
    "Always": "Always",
    "AlwaysOff": "Always off",
    "VeryFast": "Very fast",
                  // allowed time within
    "AllowedTimeWithin": "Allowed time within",
    "AllowedTimeWithinExplanation": "Number of minutes per hour with the highest/lowest O2 flow even when the patient uses a pulse oximeter.",
                  // O2 compliance goal
    "O2ComplianceGoal": "O2 compliance goal",
    "OxygenComplianceGoalExplanationPatientPage": "The minimum number of hours per day that the patient is expected to receive oxygen therapy through the device.",
    "OxygenComplianceGoalExplanationTreatmentPage": "Please specify the minimum number of hours per day that the patient is expected to receive oxygen therapy through the device.",
    "OxygenPrescription": "Minimum oxygen prescription",
    "OxygenPrescriptionExplanation": "Please specify the minimum number of hours per day that the patient must use oxygen therapy.",
    "OxygenPrescriptionUnit": "hours/day",
                  // SpO2 range
    "SpO2Range": "SpO2 range",
    "TargetSpO2RangeExplanation": "Target SpO2 range of the patient.",
    "DefineSpO2": "Define an accepteable SpO2 saturation range",
    "DefineSpO2Message": "Define an acceptable SpO2 saturation range according to patient needs",
                  // O2 flow ranges
    "O2FlowRanges": "O2 flow ranges",
    "validationError_WithoutPoMinIsSmaller": "Without PO min is smaller or equal to with PO min",
    "validationError_WithoutPoMaxIsBigger": "Without PO max is bigger or equal to with PO max",
    "LowFlowCompatibilityWarning": "Choosing a minimum flow less than 0.5 may not be supported in patient setup (ie. concentrator)",
                      // with pulse oximeter
    "O2RangeWithPO": "O2 flow range with pulse oximeter", //St (short term)
    "withPulseOximeterOn": "with pulse oximeter on",
    "WithPulseOximeterExplanation": "The O2 flow range in l/min when patient is wearing the pulse oximeter.",
    "WithPO": "With pulse oximeter",
    "O2RangeMaxWith": "Upper O2 target range with sensor",
    "O2RangeMinWith": "Lower O2 target range with sensor",
    "ShortTermLength": "Fraction of time with pulse oximeter",
    "ShortTermExplanation": "How many minutes per hour the short term flow is allowed to be active.",
    "DefineO2FlowWithPO": "Define O2 flow ranges with pulse oximeter on",
    "DefineO2FlowWithPOMessage": "Set the O2 flow range for when the patient is connected to the pulse oximeter",
    "AllowedStRange": "Allowed O2 flow with pulse oximeter",
    "O2RangeWithPOExplanation": "O2 flow range in l/min when pulse oximeter is on the finger",
                      // without pulse oximeter
    "O2RangeWithoutPOExplanation": "O2 flow range in l/min when pulse oximeter is NOT on the finger",
    "O2RangeWithoutPO": "O2 flow range without pulse oximeter", //Lt (long term)
    "WithoutPulseOximeterExplanation": "The O2 flow range in l/min when patient is NOT wearing the pulse oximeter.",
    "WithoutPO": "Without pulse oximeter",
    "O2RangeMaxWithout": "Upper O2 target range without sensor",
    "O2RangeMinWithout": "Lower O2 target range without sensor",
    "DefineO2FlowWithOutPO": "Define O2 flow ranges without pulse oximeter on",
    "DefineO2FlowWithOutPOMessage": "The O2 flow range without pulse oximeter should not exceed the O2 flow range with pulse oximeter",
    "AllowedLtRange": "Allowed O2 flow without pulse oximeter",


    // APP LICENSES PAGE
    "License": "App license",
    "AppLicenses": "App licenses",
    "AddAppLicense": "Add app license",
    "AddAppLicenses": "Add app licenses",
    "AppLicensesOverview": "App licenses overview",
    "AppLicenseDetails": "App license details",
    "AppLicenseName": "App license name",
    "ChangeSecret": "ChangeSecret", //UPDATE
    "ClientId": "Client ID",
    "ClientSecret": "Client secret",
    "DeleteAppLicenseText": "Do you really want to delete this license?",
                // roles
    "InstallerTablet": "Installer Tablet",
    "InstallerTabletDescription": "Can install a patient and their treatment profile on a HOT device and send session data. This license can also update and factory reset HOT devices.",
    "PatientTablet": "Patient Tablet",
    "PatientTabletDescription": "Can send and see latest sessions and monitor current measurements of patient SpO2, pulse and O2 flow data.",
    "DetailedSessionSender": "Detailed Session Sender",
    "DetailedSessionSenderDescription": "An add-on license to allow tablet to send detailed data.",


    // LOCATIONS PAGE
    "Location": "Location",
    "Locations": "Locations",
    "CreateLocation": "Create location",
    "AddLocation": "Add location",
    "EditLocation": "Edit location",
    "SelectLocation": "Select location",
    "LocationDetails": "Location details",
    "LocationId": "Location ID",
    "LocationName": "Location name",
    "ParentLocation": "Parent location",
    "ClinicalTrial": "Clinical trial",
    "DataRetentionPeriod": "Data retention period",

    
    // USERS PAGE
    "User":"User",
    "Users": "Users",
    "AddUser": "Add user",
    "UserDetails": "User details",
    "UserId": "User ID",
    "UserName": "User name",
    "UserLocation": "User location",
    "UserOverview": "User overview",
    "UserSearch": "Search for users",
    "UserLookupExplanation": "Search for users using the search critiria below.",
    "UserLookupExplanation1": "Get all users by searching with no criteria.",
    "UserLookupExplanation2": "Click on a user in the table to open the user page.",
    "DeleteUser": "Delete user",
    "DeleteUserText": "User will be permanently deleted.",
                // roles                
    "Role": "Role",
    "Roles": "Roles",
    "AddRole": "Add role",
    "ModifyRole": "Modify role",
    "RoleLocation": "Role location",
    "EditRoles": "Edit roles",
    "SaveRoles": "Save roles",
    "SelectRoles": "Select roles",
    "Administrator": "Administrator",
    "AdministratorDescription": "Can create, edit and view 'Locations', create, view and delete 'Licenses' and create, edit, view and delete 'Users'.",
    "Auditor": "Auditor",
    "AuditorDescription": "Can view 'Log' of actions made by users.",
    "TreatmentTemplateManager": "Treatment Template Manager",
    "TreatmentTemplateManagerDescription": "Can create and view 'Treatments'.",
    "DeviceManager": "Device Manager",
    "DeviceManagerDescription": "Can create, edit and view 'Devices' and device logs and create, view and delete 'Licenses'.",
    "PatientMonitor": "Patient Monitor",
    "PatientMonitorDescription": "Can view 'Patients'.",
    "PatientManager": "Patient Manager",
    "PatientManagerDescription": "Can create, edit and view 'Patients'.",
    "Assembler": "Assembler",
    "AssemblerDescription": "Can create new batches, create new devices and add components to devices.",
    "Tester": "Tester",
    "TesterDescription": "Can see devices ready for testing and approve tests of devices.",
    "Packager": "Packager",
    "PackagerDescription": "Can package and approve that all items are in the box prior to shipment.",
    "ProductionQa": "Production QA",
    "ProductionQaDescription": "Can approve devices and/or batches.",
    "Shipper": "Shipper",
    "ShipperDescription": "Can change owner and location to shipping location.",
    "Repairer": "Repairer",
    "RepairerDescription": "Can set device to under investigation or at repair.",
    "CtManager": "CT Manager",
    "CtManagerDescription": "Can change location parameters for clinical trials (max O2 flow and data retention period).",
    "O2maticQa": "O2matic QA",
    "O2maticQaDescription": "Can approve batches.",
    "O2maticAdministator": "O2matic Administrator",
    "O2maticAdministatorDescription": "Can add and change page notifications, device metadata options and whitelist pulse oximeters.",
    "O2maticTester": "O2matic Tester",
    "O2maticTesterDescription": "Can see devices ready for testing by O2matic and approve tests of devices.",


    // DEVICE PAGE
    "Device": "Device",
    "Devices": "Devices",
    "EditDevice": "Edit device",
    "DeviceInformation": "Device information",
    "Dmr": "DMR",
    "Sw": "SW version",
    "Fw": "FW version",
    "DeviceOwner": "Device owner",
    "Owner": "Owner",
    "DeviceLocation": "Device location",
    "UDSN": "UDSN",
    "PUID": "PUID",
    "MAC": "MAC",
    "MacAddress": "MAC address",
    "DeviceSn": "Device SN",
    "DeviceSerialNumber": "Device serial number",
    "EventLog": "Event log",
    "EventType": "Event type",
    "SystemLog": "System log",
    "DeviceLog": "Device log",
    "NumberOfDevices": "Number of devices",
    "HOT": "HOT",
    "HotDevice": "HOT-device",
    "HotDevices": "HOT-devices",
    "HotDeviceEdit": "Edit HOT-device",
    "HotDeviceDetails": "HOT-device details",
    "PRO": "PRO",
    "ProDevice": "PRO-device",
    "ProDevices": "PRO-devices",
    "ProDeviceEdit": "Edit PRO-device",
    "ProDeviceDetails": "PRO-device details",
    "QrCode": "QR code",
    "QRScanner":"QR scanner",
    "QRscannerText":"Scan the QR code on the back of a PRO or HOT device to see and edit the device information.",
    "QrCodeError": "The scanned QR code does not belong to an O2matic device.",
    


    // PRODUCTION - TRACE
    "Production":"Production",
    "DevicePending":"devices pending",
    "Assembled": "Assembled",
    "AtRepairO2matic": "At repair O2matic",
    "Packaged": "Packaged",
    "Prod QA Approved": "Production QA approved",
    "ReadyForInvestigation": "Ready for investigation",
    "ReadyForO2maticApproval": "Ready for O2matic approval",
    "ReadyForRepair": "Ready for repair",
    "ReadyForTesting": "Ready for testing",
    "ReleasedForSale": "Released for sale",
    "SCRAPPED": "SCRAPPED",
    "Shipped": "Shipped",
    "Tested": "Tested",
            // assemble
    "Assemble":"Assemble",
    "AssembleNewDevice":"Assemble new device",
    "NewBatch":"New batch",
            // test
    "Test":"Test",
    "O2maticTest":"O2matic Test",
    "SelectDeviceForTestText":"Select a device from the table for testing.",
    "TestingDeviceWithUDSN":"Testing device with UDSN",
    "DeviceTestedSuccessfully":"Device tested - successfully",
    "DeviceMovedToRepair":"Device moved to repair",
    "DeviceMovedToAtRepairO2matic":"Device moved to at repair O2matic",
    "DeviceWillBeMovedToRepair":"Not all tests are passing, device will be moved to repair",
    "TestProcedureFollowed":"Test procedure followed",
    "TestProcedureFollowedText":"Confirm that the test procedure was followed",
    "TurningOnTheDevice":"Turning on the device",
    "TurningOnTheDeviceText":"Check that the device turns on",
    "DeviceStatus":"Device status",
    "DeviceStatusText":"Check device status",
    "BatteryMode":"Battery mode",
    "BatteryModeText":"Check battery mode",
    "PulseOximeter":"Pulse oximeter",
    "PulseOximeterText":"Check pulse oximeter",
    "AlarmSpeaker1":"Alarm speaker 1",
    "AlarmSpeaker1Text":"Check alarm speaker 1",
    "AlarmSpeaker2":"Alarm speaker 2",
    "AlarmSpeaker2Text":"Check alarm speaker 2",
    "OxygenFlow":"Oxygen flow",
    "OxygenFlowText":"Check oxygen flow",
    "OxygenLeakage":"Oxygen leakage",
    "OxygenLeakageText":"Check oxygen leakage",
    "FirmwareVersion":"Firmware version",
    "FirmwareVersionText":"Select firmware version",
    "SoftwareVersion":"Software version",
    "SoftwareVersionText":"Select software version",
    "ProcessorSerialNumber":"Processor serial number (PUID)",
    "ProcessorSerialNumberText":"Enter processor serial number (PUID)",
    "ZeroFlow":"Zero flow",
    "ZeroFlowText":"Check device at 0 l/min",
    "VisualInspection":"Visual inspection",
    "VisualInspectionText":"Check device for any visual damage or defects",
    "MacAddressText":"Enter MAC address",
    "BluetoothConnection":"Bluetooth connection",
    "BluetoothConnectionText":"Check Bluetooth connection",

            // package
    "Package":"Package",
    "Packaging":"Packaging",
    "DevicePacked":"Device packed",
    "SelectDeviceForPackageText":"Select a device from the table for packaging.",
    "PackagingDeviceWithUDSN":"Packaging device with UDSN",
                      // hot steps
    "ContainsHotDevice":"Package contains HOT device",
    "TechnicalUserManual":"Technical User Manual",
    "ContainsTechnicalUserManual":"Package contains Technical User Manual",
    "InstructionsForUse":"Instructions for use",
    "ContainsInstructionsForUse":"Package contains Instructions For Use",
    "DeclarationOfConformity":"Declaration of Conformity",
    "ContainsDeclarationOfConformity":"Package contains Declaration of Conformity",
    "PowerSupply":"Power supply",
    "ContainsPowerSupply":"Package contains power supply",
                      // pro steps
    "ContainsProDevice":"Package contains PRO device",
    "Sensor":"Sensor",
    "ContainsSensor":"Package contains sensor",
    "ContainsUserManual":"Package contains User Manual",
    "MasterPin":"Master PIN",
    "ContainsMasterPin":"Package contains master pin",
    "OxygenOutputAdapter":"Oxygen output adapter",
    "ContainsOxygenOutputAdapter":"Package contains oxygen output adapter",
            // under investigation
    "UnderInvestigation":"Under investigation",
    "UnderInvestigationMessage":"Select device under investigation for next step",
    "InvestigateDeviceWithUdsn":"Investigating device with UDSN",
    "RepairProdDescription": "Select device to be repaired during production.",
    "RepairO2maticDescription": "Select device to be repaired by O2matic.",
              // QA
    "QA":"QA",
    "Qa":"QA",
    "ProductionQA":"Production QA",
    "O2maticQA":"O2matic QA",
    "O2maticqa":"O2matic QA",
    "QAAprove":"QA approve",
    "ProductionQAAproveMessage":"Select batch(es)/device(s) to be production QA approved",
    "ProductionQAAprove":"Production QA approve",
    "O2maticQAAprove":"O2matic QA approve",
    "O2maticQaApproveText": "Are you sure you want to O2matic QA approve this device?",
    "O2maticQAAproveMessage":"Select batch(es)/device(s) to be O2matic QA approved",
    "O2maticTestedQAAproveMessage":"Select device to be O2matic QA approved",
    "ProductionQAApprovedReady":"Production QA approved devices ready for O2matic QA approval",
    "O2maticTestedReady":"O2matic tested devices ready for O2matic QA approval",
              // ship
    "Ship":"Ship",
    "ShipperMessage":"Select batch(es)/device(s) and location to be shipped",
              // repair
    "Repair":"Repair",
    "RepairOverviewMessage":"Select device to be repaired",
    "RepairDeviceWithUdsn":"Repairing the device with UDSN",
    
    "SelectLocationFirst":"Select location first",
    "ContactO2maticToResolve":"Verify data, if problem persist contact O2matic to resolve the issue",
    "PrintSerial":"Print serial",
    "FlowmeterSerial":"Flowmeter serial",
    "ValveSerial":"Valve serial",
    "OximeterSerial":"Pulse oximeter serial",
    "OwnerLocationChanged":"Device's owner and location changed",
    "SelectOwner":"Select owner",
    "SelectStatus":"Select status",
    "ComponentId": "Component ID",
    "Components":"Components",
    "Chassis":"Chassis",
    "OriginalValue": "Original value",
    "NewValue": "New value",
    "Flowmeter":"Flowmeter",
    "Valve":"Valve",
    "Print":"Print",
    "Screen":"Screen",
    "Oximeter":"Pulse oximeter",
    "Hot100Chassis":"HOT 100 chassis",
    "Hot100Print":"HOT 100 print",
    "Hot100Battery":"HOT 100 battery",
    "Hot100Device":"HOT 100 device",
    "Pro100Device":"PRO 100 device",
    "DuplicateEntry":"Duplicate entry",
    "duplicateMAC":"Duplicate MAC address",
    "duplicatePrint":"Duplicate print serial",
    "duplicateFlowmeter":"Duplicate flowmeter serial",
    "duplicateValve":"Duplicate valve serial",
    "duplicateOximeter":"Duplicate pulse oximeter serial",


    // LOGS PAGE
    "Log": "Log",
    "Logs": "Logs",
    "AuditorLogs": "Auditor Logs",
    "ListOfO2maticUsers": "List of O2matic users",
    "ListDownloaded":"List of O2matic users downloaded on the:",
    "RolesApprovedBy":"Roles of all O2matic users are approved by:",
    "Action": "Action",
    "Actions": "Actions",
    "Domain": "Domain",
    "LogDomain": "Log domain",
    "TargetObjectId": "Target object ID",
    "TargetObjectLocation": "Target object location",


    // INFORMATION PAGE
    "Info": "Info",
    "Information": "Information",
    "ForO2maticHotAppSee": "For 'O2matic - HOT' app see",
    "UserManual":"User Manual",
    "TechnicalManual": "Technical Manual",
    "InstructionsAndGuides": "Instructions and guides",
    "InstructionsDescription": "Click on the language you want to view the instruction or guide in. File will open as a pdf in a new window. For other versions and for instruction for PRO devices, please see www.o2matic.com.",
    "ProReportGenerator": "PRO report generator",
    "ReleaseNotes": "Release notes",
    "ContactInformation": "Contact information",
    "Webpage": "Webpage",

    
    // MAIN PAGE
    "HomeMessage1": "Connects patients in oxygen treatment with their health care professionals.",
    "HomeMessage2": "A solution that improves the safety and quality of life for the patients while simultaneously facilitating the workload and reducing the need of physical contact for healthcare professionals.",
    "LoggingOut": "You are being logged out.",
    "LoginHelpText": "Contact your administrator for login credentials",
    "LoginPageHeader": "Demo page",
    "Training": "Training",
    "LogOut": "Log out",
    "SignIn": "Sign in",
    "UnauthorizedPage": "You are not authorized to view this page.",
                  // updates banner
    "UpdatesBanner": "Updates - Click to see a description of new features and changes on this page",
    "UpdatesHeader": "Updates - Here is a description of some of the new features and changes",
    "UpdatesLine1": "For an overview of all new features and changes of the latest release, please see the release notes found on the 'Info' page.",
    "UpdatesLine2": "New features on this page:",
    "UpdatesDescriptionInformationPage": "This page is a new feature and serves to hold general contact information, latest release notes of the O2matic Portal along with instructions and guides for the O2matic Portal and for the HOT device.",
    "UpdatesDescriptionPROPage": "This page has gotten a new layout, but contains the same information as previously, and 'Event Log' is now called 'Device Log'.",
    "UpdatesDescriptionHOTPage": "This page has gotten a new layout, but contains the same information as previously, and 'Event Log' is now called 'Device Log'.",
    "UpdatesDescriptionDevicesPage": "This page contains access to the device QR-scanner, HOT-devices and PRO-devices pages.",
    "UpdatesDescriptionPatientPage":"This page has gotten a new layout where all patient related information is gathered in leftmost blue box, all treatment related information is in the rightmost and device and technical information is in the bottom blue box. NEW: There is now a prescribed timestamp for the treatment, which is when the latest change has been made to the treatment. NEW: The treatment status indicates if the treatment has been installed on the HOT device or not. If treatment has been installed, the timestamp of installation will be displayed, else installed status will say 'Pending'. NEW: If the location of the patient and device does not match, 'Location Mismatch' will be shown next to the device location and the location name for the HOT device and patient will be orange. Locations should match to insure propper data transfer, which is done by changing either the location of the patient or by contactining a user with the 'Device Manager' role to update the location of the HOT device. NEW: In the blue device information box is now included the timestamp of when device was initially assigned to the patient, last received device log, timestamp of latest session and time until next session has to be made. Chart layouts have been updated (a guide for reading the charts can be found in the 'Portal Manual' found on the 'Info' page). The sessions overview chart was previously location inside a tab on the page, but is now displayed at the top of the page for easy access. The previous 'Sessions' tab is now called 'Data archive' and contains both session reports and daily reports (for descriptions of the two report types, see the 'Portal Manual'). The 'Live data' feature has been moved to the top of the page inside the blue patient blox (see 'Portal Manual' for description of the live data feature).",
    "UpdatesDescriptionPatientsPage": "This page now contains a better search function, where you can search for patients by O2matic ID, Identifier value, Name, Date of birth and Location. Searching by Location will provide you with a table of all patients on that location.",
    "UpdatesDescriptionUsersPage": "This page now contains a better search function, where you can search for users by ID, First name, Last name, Email, User location and specific roles. Clicking the search button with no search criteria filled out will provide you with a table of all users.",
    "UpdatesDescriptionUserPage": "This page has a new layout, and a better overview of the roles and corresponsing locations of the user. Clicking 'Edit roles' enables you to add/remove roles, add roles to new locations or to delete user.",
    "UpdatesDescriptionQRScannerPage": "This is a new page where you can scan the QR code on the back of a PRO or HOT device to see and edit the device information.",
    "UpdatesDescriptionTreatmentPage":"The treatment page has gotten a rephrasing of the treatment parameters but contains the same parameters as previously.",
    "UpdatesDescriptionTreatmentCreatePage":"The treatment creation page has gotten a rephrasing of the treatment parameters but contains the same parameters as previously.",
    "UpdatesDescriptionLogsPage": "This page now contains date-pickers to select a specific interval of time to view logs. The logs are also downloadable as a CSV fil, yet any filtering applied to the table will not apply in the csv files.",
    "UpdatesDescriptionAppLicensePage": "This page has gotten a new layout, but contains the same information as previously.",
    "UpdatesDescriptionLocationPage": "This page has gotten a new layout, but contains the same information as previously.",
    "UpdatesDescriptionPatientCreatePage": "The treatment part of this page has gotten a rephrasing of the treatment parameters but contains the same parameters as previously.",
    "UpdatesDescriptionPatientEditPage": "The treatment part of this page has gotten a rephrasing of the treatment parameters but contains the same parameters as previously.",

    // PRO REPORT
    "ProReport":"PRO report",
    "CreateProReport": "Create PRO report",
    "ProReportMessage":"Upload PRO session files and generate the report. No data are saved or transfered.",

    // GENERAL
    "Create":"Create",
    "Cancel":"Cancel",
    "Close": "Close",
    "Clear": "Clear",
    "Delete": "Delete",
    "Search": "Search",
    "Save": "Save",
    "Submit": "Submit",
    "Finish": "Finish",
    "Select":"Select",
    "Open":"Open",
    "Edit": "Edit",
    "Back": "Back",
    "Continue": "Continue",
    "Update": "Update",
    "Read": "Read",
    "Replace":"Replace",
    
    "Email": "Email",
    "Remark": "Remark",
    "Remarks": "Remarks",
    "Data": "Data",
    "Generated":"Generated",
    "Last": "Last",
    "Format":"Format",
    "Version":"Version",
    "Owner":"Owner",
    "SelectType":"Select type",
    "SelectTags":"Select tags",
    "Tag":"Tag",
    "Tags":"Tags",
    "PleaseConfirm": "Are you sure?",
    "Title": "Title",
    "Status": "Status",
    "Pulse": "Pulse",
    "Name": "Name",
    "Normal": "Normal",
    "and": "and",
    "Delay": "Delay",
    "Message": "Message",
    "Active": "Active",
    "HomePage": "Home page",
    "GoBackTo": "Go back to",
    "Id": "ID",
    "Signature": "Signature",
    "Created": "Created",
    "AddLog": "Add log",
    "LogEntry":"Log entry",
    "Added":"Added",
    "SelectLocation": "Select location",
    "Parameter": "Parameter",
    "Description": "Description",
    "Severity": "Severity",
    "Duration": "Duration",
    "Length":"Length",
    "SuperAdmin":"Super administrator",
    "RegExpEmoji":"Text field can not include pictograms or emojis.",
    "DragNDropFile": "Drag 'n' drop some files here, or click to select files.",
    "":"",
    "None": "None",
    "*empty*": "*empty*",
    "Disable": "Disable",
    "Disabled": "Disabled",
    "Enable": "Enable",
    "Enabled": "Enabled",
    "From": "From",
    "To": "To",
    "Yes": "Yes",
    "No": "No",
    "Lower": "Lower",
    "Higher": "Higher",
                // download
    "Download": "Download",
    "FilesToBeDownloaded": "Files to be downloaded",
    "ConfirmDownload": "Confirm download",
    "DownloadAsPdf": "Download as pdf",
    "DownloadCsv": "Download as CSV",
    "DownloadCSV":"Download CSV",
    "DownloadPdf": "Download as pdf",
    "Downloaded": "Downloaded",
                // statistics
    "Statistics": "Statistics",
    "MODE":"Mode",
    "Mode":"Mode",
    "MINIMUM":"Minimum",
    "Minimum":"Minimum",
    "Min": "Min",
    "MAXIMUM":"Maximum",
    "Maximum":"Maximum",
    "Max": "Max",
    "AVERAGE":"Average",
    "Average":"Average",
    "average": "average",
    "AverageRange":"Average range",
                // units and time
    "BPM": "BPM",
    "bpm": "bpm",
    "l/min": "l/min",
    "LPM": "L / min",
    "MinutesPerHour": "Minutes per hour",
    "min/hour": "min/hour",
    "hour/day": "hour/day",
    "hours/day": "hours/day",
    "Sec": "Sec",
    "sec": "sec",
    "min": "min",
    "hours": "hours",
    "Day": "Day",
    "Days": "Days",
    "Month": "Month",
    "Months": "Months",
    "Year": "Year",
    "Years": "Years",
    "years": "years",
    "Date": "Date",
    "Dates": "Dates",
    "LocalTime": "Local time",
    "StartTime":"Start time",
    "EndTime":"End time",
    "EntireDay": "Entire day",
    "LocalTimestamp": "Local timestamp",
    "UTCTimestamp":"UTC timestamp",
    "UnixTimestamp": "Unix timestamp",
    "Timestamp": "Timestamp",
    "Time": "Time",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
                // feedback
    "NoData": "No data",
    "NoRows": "No rows",
    "NoResults": "Filtering returns no results.",
    "NoRowsOximeters": "No oximeters found.",
    "NoRowsMetadata": "No metadata found.",
    "NoRowsPatients": "No patients found.",
    "NoRowsTreatments": "No treatment profiles found.",
    "NoRowsLicenses": "No licenses found.",
    "NoRowsLocations": "No locations found.",
    "NoRowsUsers": "No users found.",
    "NoRowsDevices": "No devices found.",
    "NoRowsLogs": "No logs found.",
    "InvalidDate": "Invalid date",
    "InvalidValue": "Invalid value",
    "NotFoundPage": "The page cannot be found",
    "NoTreatments": "No treatments",
    "NotSelected": "Not selected",
    "Success": "Success",
    "BadRequest": "Bad request",
    "Unauthorized": "Unauthorized",
    "NotFound": "Not found",
    "IntenalServerError": "Intenal server error",
    "UnknownError": "Unknown error",
    "Error": "Error",
    "Loading": "Loading...",
                  // country and language
    "Country-CA": "Canada",
    "Country-IT": "Italy",
    "Country-DK": "Denmark",
    "Country-DE": "Germany",
    "Country-ES": "Spain",
    "Country-VN": "Vietnam",
    "Country-UM": "United States Minor Outlying Islands",
    "Country-AE": "United Arab Emirates",
    "Country-UA": "Ukraine",
    "Country-TR": "Turkey",
    "Country-TH": "Thailand",
    "Country-TW": "Taiwan",
    "Country-CH": "Switzerland",
    "Country-LK": "Sri Lanka",
    "Country-ES": "Spain",
    "Country-SI": "Slovenia",
    "Country-SK": "Slovakia",
    "Country-SG": "Singapore",
    "Country-RS": "Serbia",
    "Country-SA": "Saudi Arabia",
    "Country-RU": "Russia",
    "Country-RO": "Romania",
    "Country-QA": "Qatar",
    "Country-PR": "Puerto Rico",
    "Country-PT": "Portugal",
    "Country-PL": "Poland",
    "Country-PH": "Philippines",
    "Country-PE": "Peru",
    "Country-PA": "Panama",
    "Country-OM": "Oman",
    "Country-NZ": "New Zealand",
    "Country-NL": "Netherlands",
    "Country-MC": "Monaco",
    "Country-MX": "Mexico",
    "Country-MT": "Malta",
    "Country-MY": "Malaysia",
    "Country-MK": "North Macedonia",
    "Country-LU": "Luxembourg",
    "Country-LI": "Liechtenstein",
    "Country-LV": "Latvia",
    "Country-KW": "Kuwait",
    "Country-KR": "South Korea",
    "Country-KZ": "Kazakhstan",
    "Country-JO": "Jordan",
    "Country-JP": "Japan",
    "Country-IT": "Italy",
    "Country-IE": "Ireland",
    "Country-ID": "Indonesia",
    "Country-IN": "India",
    "Country-IS": "Iceland",
    "Country-HU": "Hungary",
    "Country-HK": "Hong Kong",
    "Country-GR": "Greece",
    "Country-FR": "France",
    "Country-FI": "Finland",
    "Country-FO": "Faroe Islands",
    "Country-EE": "Estonia",
    "Country-EG": "Egypt",
    "Country-EC": "Ecuador",
    "Country-CZ": "Czech Republic",
    "Country-CY": "Cyprus",
    "Country-HR": "Croatia",
    "Country-CR": "Costa Rica",
    "Country-CN": "China",
    "Country-CA": "Canada",
    "Country-BG": "Bulgaria",
    "Country-BR": "Brazil",
    "Country-BV": "Bouvet Island",
    "Country-BA": "Bosnia and Herzegovina",
    "Country-BE": "Belgium",
    "Country-BY": "Belarus",
    "Country-BD": "Bangladesh",
    "Country-BH": "Bahrain",
    "Country-AZ": "Azerbaijan",
    "Country-AT": "Austria",
    "Country-AU": "Australia",
    "Country-AM": "Armenia",
    "Country-AR": "Argentina",
    "Country-DZ": "Algeria",
    "Country-AL": "Albania",
    "Country-GB": "United Kingdom",
    "Country-SE": "Sweden",
    "Country-LT": "Lithuania",
    "Country-US":"United States of America",
    "Country-NO": "Norway",

    "Language-it": "Italian",
    "Language-en": "English",
    "Language-da": "Danish",
    "Language-de": "German",
    "Language-es": "Spanish",
  }
}
export default English;
